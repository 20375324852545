<template>
    <div id="main" class="feedbackPage">
        <div class="main">
            <van-sticky>
                <van-nav-bar
                        title="精准作业"
                        left-arrow
                        :left-text="'首页'"
                        @click-left="$router.push({path:'/'})"
                />
            </van-sticky>
            <van-sticky :offset-top="topH">
                <div class="van-hairline--bottom titel" style="background-color: #fff;">
                    <van-row>
                        <van-col span="18">
                            <h4>{{ name }}</h4>
                        </van-col>
                        <van-col span="6">
                          <span class="title_r" style="font-size:12px">
                            <van-checkbox v-model="showText" shape="square">题目信息</van-checkbox>
                          </span>
                        </van-col>
                    </van-row>
                </div>
            </van-sticky>

            <van-cell v-for="(item, index) in list" :key="index">
                <template slot="title">
                    <div class="cell_title">
                        <span>{{ item.index }}</span>
                        <span>【{{item.typeName}}】</span>
                    </div>
                    <div class="cell_title" v-show="showText">
                        <div v-html="item.text" class="box questionText"></div>
                    </div>
                    <div class="cell_title"
                         v-show="showText && (item.question_type=='multiple_one'||item.question_type=='multiple_many')">
                        <div v-for="(rs, key) in item.options" :key="key" class="box questionText">
                            <span>{{rs.key}}.&nbsp;</span>
                            <span v-html="rs.text"></span>
                        </div>
                    </div>

                    <div class="cell_title" v-if="item.question_type === 'big_spaces' && item.sub_question.length > 0">
                        <div class="cell_title" v-for="(sub_question,key) in item.sub_question"  :key="sub_question.id">
                            <div class="cell_title">
                                <span>{{item.index}}-{{ key+1 }} :<span>【{{sub_question.question_type_name}}】</span></span>
                                <div v-show="showText" v-html="sub_question.text" class="box questionText"></div>
                            </div>

                            <div class="cell_title questionText"
                                 v-show="showText && (sub_question.question_type=='multiple_one'||sub_question.question_type=='multiple_many')">
                                <div v-for="(rs, key) in sub_question.options" :key="key" class="box questionText">
                                    <span>{{rs.key}}.&nbsp;</span>
                                    <span v-html="rs.text"></span>
                                </div>
                            </div>

                            <div class="cell_title" v-if="sub_question.question_type === 'empty_spaces'">
                                <van-row class="mb10 maximg" v-if="sub_question.correctable === 'studentCorrecte'">
                                    <van-col span="4">答案：</van-col>
                                    <van-col span="20">
                                <span class="mr5" v-for="(answer,answerKey) in sub_question.question_answer" :key="answerKey">
                                    <span v-show="Object.keys(sub_question.question_answer).length > 1">（{{answerKey + 1}}） </span><span class="mr5" v-html="answer"></span><br>
                                </span>
                                    </van-col>
                                </van-row>
                            </div>
                            <div class="cell_desc">
                                <van-row class="mb10">
                                    <van-col span="6">我的作答：</van-col>
                                    <van-col span="18" class="optionAnswer" v-if="sub_question.question_type === 'multiple_one' || sub_question.question_type === 'multiple_many'">
                                        <van-checkbox-group
                                            class="checkbox"
                                            v-model="sub_question.studentAnswer"
                                            direction="horizontal"
                                            v-if="sub_question.question_type == 'multiple_many'"
                                            :disabled="correctable"
                                        >
                                            <van-checkbox
                                                v-for="(rs, key) in sub_question.options"
                                                :key="key"
                                                :name="rs.value"
                                            >
                                                <span>{{ rs.key }}</span>
                                            </van-checkbox>
                                        </van-checkbox-group>
                                        <van-radio-group
                                            class="checkbox"
                                            v-model="sub_question.studentAnswer"
                                            direction="horizontal"
                                            v-else-if="sub_question.question_type == 'multiple_one'"
                                            :disabled="correctable"
                                        >
                                            <van-radio v-for="(rs, key) in sub_question.options" :key="key" :name="rs.value">
                                                <span>{{ rs.key }}</span>
                                            </van-radio>
                                        </van-radio-group>
                                    </van-col>
                                    <van-col span="18" class="optionAnswer" v-if="sub_question.question_type == 'empty_spaces'">
                                        <van-row v-for="(score,scoreKey) in sub_question.empty_score" :key="scoreKey"
                                                 class="empty_cell">
                                            <van-col span="3" style="line-height: 27px;" v-if="sub_question.empty_score.length > 1"> ({{scoreKey}})</van-col>
                                            <van-col span="21">
                                                <van-radio-group
                                                    class="checkbox"
                                                    v-if="sub_question.correctable === 'studentCorrecte'"
                                                    v-model="sub_question.studentAnswer[scoreKey]"
                                                    direction="horizontal"
                                                    :disabled="correctable"
                                                >
                                                    <van-radio v-for="(rs, key) in sub_question.empty_options" :key="key" :name="rs.value">
                                                        <van-icon
                                                            class-prefix="my-icon"
                                                            name="dui"
                                                            v-if="rs.key == 'allRight'"
                                                        />
                                                        <van-icon
                                                            class-prefix="my-icon"
                                                            name="bandui"
                                                            v-else-if="rs.key == 'halfRight'"
                                                        />
                                                        <van-icon
                                                            class-prefix="my-icon"
                                                            name="cuo"
                                                            v-else-if="rs.key == 'allWrong'"
                                                        />
                                                    </van-radio>
                                                </van-radio-group>

                                            </van-col>
                                        </van-row>

                                    </van-col>

                                    <!--  题目图片上传区域 -->
                                    <van-row v-if="sub_question.question_type === 'empty_spaces'" style="text-align: center">
                                        <van-col span="24">
                                            <van-uploader v-model="sub_question.imgUrl"
                                                          :after-read="(res,file) => {uploadImage(sub_question, res,file)}" :max-count="1">

                                            </van-uploader>
                                        </van-col>

                                    </van-row>
                                </van-row>
                            </div>

                        </div>
                    </div>
                    <div class="cell_title" v-if="item.question_type === 'empty_spaces'">
                        <van-row class="mb10 maximg" v-if="item.correctable === 'studentCorrecte'">
                            <van-col span="4">答案：</van-col>
                            <van-col span="20">
                                <span class="mr5" v-for="(answer,answerKey) in item.question_answer" :key="answerKey">
                                    <span v-if="Object.keys(item.question_answer).length > 1">（{{parseInt(answerKey + 1)}}） </span>
                                    <span class="mr5" v-html="answer"></span><br>
                                </span>
                            </van-col>
                        </van-row>
                        <!--<div class="maximg" v-if="item.correctable === 'studentCorrecte'">
                            <div class="answer_row">答案:</div>
                            <div class="answer_text">
                                <span class="mr5" v-for="(answer,answerKey) in item.question_answer" :key="answerKey">
                                    <span>({{answerKey}}) </span><span class="mr5" v-html="answer"></span><br>
                                </span>
                            </div>
                        </div>-->
                    </div>
                </template>
                <template slot="label" v-if="item.question_type !== 'big_spaces'">
                    <div class="cell_desc">
                        <van-row class="mb10">
                            <van-col span="6">我的作答：</van-col>
                            <van-col span="18" class="optionAnswer" v-if="item.question_type === 'multiple_one' || item.question_type === 'multiple_many'">
                                <van-checkbox-group
                                    class="checkbox"
                                        v-model="item.studentAnswer"
                                        direction="horizontal"
                                        v-if="item.question_type == 'multiple_many'"
                                        :disabled="correctable"
                                >
                                    <van-checkbox
                                            v-for="(rs, key) in item.options"
                                            :key="key"
                                            :name="rs.value"
                                    >
                                        <span>{{ rs.key }}</span>
                                    </van-checkbox>
                                </van-checkbox-group>
                                <van-radio-group
                                    class="checkbox"
                                        v-model="item.studentAnswer"
                                        direction="horizontal"
                                        v-else-if="item.question_type == 'multiple_one'"
                                        :disabled="correctable"
                                >
                                    <van-radio v-for="(rs, key) in item.options" :key="key" :name="rs.value">
                                        <span>{{ rs.key }}</span>
                                    </van-radio>
                                </van-radio-group>
                            </van-col>

                            <van-col span="18" class="optionAnswer" v-if="item.question_type === 'empty_spaces' && item.correctable === 'studentCorrecte'">
                                <van-row v-for="(score,scoreKey) in item.empty_score" :key="scoreKey"
                                         class="empty_cell">
                                    <van-col span="3" style="line-height: 27px;" v-if="Object.keys(item.empty_score).length > 1"> ({{scoreKey}})</van-col>
                                    <van-col span="21">
                                        <van-radio-group
                                            class="checkbox"
                                                v-if="item.correctable === 'studentCorrecte'"
                                                v-model="item.studentAnswer[scoreKey]"
                                                direction="horizontal"
                                                :disabled="correctable"
                                        >
                                            <van-radio v-for="(rs, key) in item.empty_options" :key="key" :name="rs.value">
                                                <van-icon
                                                        class-prefix="my-icon"
                                                        name="dui"
                                                        v-if="rs.key == 'allRight'"
                                                />
                                                <van-icon
                                                        class-prefix="my-icon"
                                                        name="bandui"
                                                        v-else-if="rs.key == 'halfRight'"
                                                />
                                                <van-icon
                                                        class-prefix="my-icon"
                                                        name="cuo"
                                                        v-else-if="rs.key == 'allWrong'"
                                                />
                                            </van-radio>
                                        </van-radio-group>

                                    </van-col>
                                </van-row>

                            </van-col>

                            <!--  题目图片上传区域 -->
                            <van-row v-if="item.question_type === 'empty_spaces'">
                                <van-col span="24">
                                    <van-uploader v-model="item.imgUrl"
                                                  :after-read="(res,file) => {uploadImage(item, res,file)}" :max-count="1">

                                    </van-uploader>
                                </van-col>

                            </van-row>
                        </van-row>
                    </div>
                </template>
            </van-cell>

            <van-row class="original">
                <van-col span="6" class="tit">上传原卷</van-col>
                <van-col span="18">
                    <van-row>
                        <van-col span="12">
                            <van-uploader v-model="original.a" :after-read="(res,file) => {uploadImage('a', res,file)}"
                                          :max-count="1">
                            </van-uploader>
                        </van-col>
                        <van-col span="12">
                            <van-uploader v-model="original.b" :after-read="(res,file) => {uploadImage('b', res,file)}"
                                          :max-count="1">
                            </van-uploader>
                        </van-col>
                    </van-row>
                </van-col>
            </van-row>
        </div>

        <div class="footer">
            <div class="foot">
                <van-button color="#019fe8" :disabled="correctable" block @click="butClick">提交</van-button>
            </div>
        </div>
        <div class="loading" v-show="loading">
            <van-loading vertical color="#1989fa" size="50" text-size="16px">加载中...</van-loading>
        </div>
        <van-image-preview v-model="images.show" :images="images.data" :start-position="images.index"/>
    </div>
</template>
<script>
    import {taskApi, wxApi} from '@/api/index'
    import commonApi from '@/api/common'
    import {replaceInfo, handleCache} from '@/common/index'
    // import iosRoute from '@/common/iosRoute.js'
    import wx from 'weixin-js-sdk'
    import common from '../../store/modules/common'

    export default {
        // mixins: [iosRoute],
        data() {
            return {
                showText: 0,
                loading: true,
                studentId: this.$route.query.studentId,
                testId: this.$route.query.testId,
                classId: this.$route.query.classId,
                correctableType: this.$route.query.correctable,
                name: '',
                list: [],
                submitAnswer: {
                    test_id: undefined,
                    student_id: undefined,
                    subject_id: undefined,
                    data: [],    //{ partitionid:"题目分区编号",show_order:"题目序号",studentAnswer:["A","B"],studentAnswerScore:"得分",studentAnswerUrl:''}
                    original_a: undefined,
                    original_b: undefined,
                },
                correctable: true,
                appId: 'wx61b727cc8f60e00a',
                pageKey: '',
                images: {
                    show: false,
                    index: 0,
                    data: []
                },
                original: {
                    a: [],
                    b: []
                },

            }
        },

        activated() {
            this.pageKey = this.studentId + this.testId + this.classId
            // this.getWxCongig()
            this.getList()
        },
        computed: {
            topH() {
                let topH = '46px';
                let winW = document.documentElement.clientWidth || document.body.clientWidth;
                console.log('winW', winW);
                if (winW >= 768) {
                    topH = '92px';
                }
                return topH;
            },
        },
        methods: {
            // 获取微信配置
            getWxCongig() {
                let _url = window.location.href
                let params = {
                    url: _url,
                    appId: this.appId
                }
                wxApi.getSignature(params).then(res => {
                    if (res.success) {
                        wx.config({
                            appId: this.appId, // 必填，公众号的唯一标识
                            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                            nonceStr: res.data.noncestr, // 必填，生成签名的随机串
                            signature: res.data.signature, // 必填，签名
                            jsApiList: ['chooseImage', 'uploadImage', 'getLocalImgData']
                        })
                    }
                })
            },
            // 拍照或选择相册
            chooseImageFun(index, original = false) {
                let that = this
                console.log(index, original);
                // wx.chooseImage({
                //   count: 1, // 默认9
                //   sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                //   sourceType: ['album', 'camera'],
                //   success: function(res) {
                //     // 如果是上传原卷
                //     if (original) {
                //       that.original[index]['butLoading'] = true
                //     } else {
                //       that.list[index]['butLoading'] = true
                //     }

                //     setTimeout(() => {
                //       wx.uploadImage({
                //         localId: res.localIds.toString(), // 需要上传的图片的本地ID，由chooseImage接口获得
                //         isShowProgressTips: 1, // 默认为1，显示进度提示
                //         success: function(rs) {
                //           // iOS WKWebview 下使用
                //           if (window.__wxjs_is_wkwebview) {
                //             wx.getLocalImgData({
                //               localId: res.localIds.toString(), // 图片的localID
                //               success: function(rss) {
                //                 // 如果是上传原卷
                //                 if (original) {
                //                   that.original[index]['imgUrl'] = rss.localData
                //                 } else {
                //                   that.list[index]['imgUrl'] = rss.localData
                //                 }
                //               },
                //               fail: function(rss) {
                //                 alert(JSON.stringify('ios' + rss))
                //               }
                //             })
                //           } else {
                //             // 如果是上传原卷
                //             if (original) {
                //               that.original[index]['imgUrl'] = res.localIds
                //             } else {
                //               that.list[index]['imgUrl'] = res.localIds
                //             }
                //           }
                //           // 如果是上传原卷
                //           if (original) {
                //             that.original[index]['imgId'] = rs.serverId
                //             that.original[index]['butLoading'] = false
                //           } else {
                //             that.list[index]['imgId'] = rs.serverId
                //             that.list[index]['butLoading'] = false
                //           }

                //           handleCache(that.pageKey, that.list)
                //           // 如果是上传原卷
                //           if (original) {
                //             handleCache(that.pageKey + 'original', that.original)
                //           }
                //         },
                //         fail: function(rs) {
                //           alert(JSON.stringify(rs))
                //         }
                //       })
                //     }, 100)
                //   },
                //   fail: function() {
                //     that.$toast.fail('操作超时，页面将自动刷新')
                //     setTimeout(() => {
                //       location.reload()
                //     }, 2000)
                //   }
                // })
            },

            /**
             * 压缩图片方法
             * @param base64  ----baser64文件
             * @param scale ----压缩比例 画面质量0-9，数字越小文件越小画质越差
             * @param callback ---回调函数
             */
            compress(base64, scale, callback) {

                // 处理缩放，转换格式
                // new 一个图片，用canvas来压缩
                const img = new Image()
                img.src = base64
                img.onload = function () {
                    const canvas = document.createElement('canvas')
                    const ctx = canvas.getContext('2d')
                    canvas.setAttribute('width', this.width)
                    canvas.setAttribute('height', this.height)
                    ctx.clearRect(0, 0, canvas.width, canvas.height)
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
                    // 转成base64 文件
                    let base64 = canvas.toDataURL('image/jpeg')
                    // 最大尺寸 单位兆（M）
                    let maxSize = 1;
                    if (base64.length > 1024 * 1024 * maxSize){
                        scale = (1024 * 1024 * maxSize) / base64.length
                    }
                    console.log(`执行缩放程序，scale=${scale}`,scale,base64.length,1024 * 1024 * maxSize)
                    base64 = canvas.toDataURL('image/jpeg', scale)

                    // while (base64.length > 1024 * 1024 * maxSize) {
                    //     scale -= 0.01
                    // }
                    console.log('****压缩后的大小****', `${Math.floor(base64.length / 1024 / 1024 * 100) / 100} M`);
                    callback(base64)
                }
            },

            //图片上传到服务器
            uploadImage(item, file) {
                // 此时可以自行将文件上传至服务器
                if (file.file.type !== 'image/jpeg' && file.file.type !== 'image/png' && file.file.type !== 'image/jpg') {
                    this.$toast.fail('请上传图片');
                    return true;
                }
                let uploadImageParams = {
                    subject_id : this.submitAnswer.subject_id,
                    image : file.content
                }
                /*commonApi.uploadImage(uploadImageParams).then(res => {
                    if(res.code === 200){
                        if(item === 'a'){
                            this.submitAnswer.original_a = res.data
                        }else if(item === 'b'){
                            this.submitAnswer.original_b = res.data
                        }else if(typeof(item) == 'object'){
                            item.studentImage = res.data;
                        }

                    }
                })*/
                // return true;

                //图片压缩
                this.compress(file.content, 0.7, (base64) => {
                    // console.log('压缩结果：base64', base64);

                    // return false;

                    let uploadImageParams = {
                        subject_id: this.submitAnswer.subject_id,
                        image: base64
                    }
                    // console.log('uploadImageParams',uploadImageParams);
                    commonApi.uploadImage(uploadImageParams).then(res => {
                        if (res.code === 200) {
                            if (item === 'a') {
                                this.submitAnswer.original_a = res.data
                            } else if (item === 'b') {
                                this.submitAnswer.original_b = res.data
                            } else if (typeof (item) == 'object') {
                                item.studentImage = res.data;
                            }
                        }
                    })
                });
            },
            // 内容临时存储
            contentBinding() {
                // let data = []
                // this.list.forEach(item => {
                //   let obj = {
                //     imgUrl: item.imgUrl,
                //     imgId: item.imgId,
                //     studentAnswer: item.studentAnswer
                //   }
                //   data.push(obj)
                // })
                // handleCache(this.pageKey, data)
            },
            // 图片预览
            imgPreview(url) {
                if (url) {
                    let index = 0,
                        data = []
                    for (const item of this.list) {
                        if (item.imgUrl) {
                            data.push(item.imgUrl)
                        }
                    }
                    for (const item of this.original) {
                        if (item.imgUrl) {
                            data.push(item.imgUrl)
                        }
                    }

                    index = data.findIndex(rs => {
                        return url == rs
                    })

                    this.images.data = data
                    this.images.index = index
                    this.images.show = true
                }
            },
            textChange() {
                this.loading = false
                setTimeout(() => {
                    window.MathJax.Hub.Queue([
                        'Typeset',
                        window.MathJax.Hub,
                        document.getElementById('main')
                    ])
                }, 500)
            },
            getList() {
                let params = {
                    studentId: this.studentId,
                    testId: this.testId,
                    classId: this.classId
                }
                taskApi.getStudentTestPaperNew(params).then(res => {
                    this.list = []
                    this.correctable = !res.data.correctable
                    this.name = res.data.name
                    this.submitAnswer.subject_id = res.data.subjectid
                    if (res.success) {
                        // 如果有缓存
                        // if (handleCache(this.pageKey)) {
                        // this.original = handleCache(this.pageKey).data
                        // this.submitAnswer = handleCache(this.pageKey).data
                        // }

                        // 补全图片路径
                        res.data.data = replaceInfo(res.data.data)
                        res.data.data.forEach(item => {
                            // 如果填空题并且有多个空格，按空格增加行数

                            let obj = {
                                partitionid: item.partitionid,
                                // showNum: (item.spaceFormat[key]?item.spaceFormat[key]:`${item.showOrder}(${key + 1})`),
                                showNum: `${item.show_order} :`,
                                showOrder: item.show_order,
                                type: item.type,
                                typeName: item.typeName,
                                question_type : item.question_type,
                                questionParentText: item.questionParentText,
                                text: item.text,
                                fullMarkScore: item.fullMarkScore,
                                answer: item.answer,
                                options: item.options,
                                studentAnswer: [],
                                empty_score : item.empty_score,
                                index : item.index,
                                empty_options : item.empty_options,
                                question_answer : item.question_answer,
                                imgUrl: [],
                                studentImage: "",
                                imgId: '',
                                butLoading: false,
                                butShow: false,
                                correctable: item.correctable,
                                sub_question: item.sub_question
                            };
                            //大题小问格式
                            if (item.question_type === 'big_spaces' && item.sub_question.length > 0) {
                                item.sub_question.forEach(sub_question => {
                                    sub_question.studentAnswer = [];
                                    sub_question.imgUrl = [];
                                    sub_question.studentImage = [];
                                })
                            }

                            // if (item.fullMarkScore && typeof (item.fullMarkScore) == "object") {
                            //     for (let v in item.fullMarkScore) {
                            //         obj.studentAnswer[v] = []
                            //     }
                            // }

                            /*let answer = []
                            if (typeof (item.answer) === "object") {
                                for (const v in item.answer) {
                                    answer.push(item.answer[v])
                                }
                            }
                            obj.answer = answer.join('<br />')*/

                            this.list.push(obj)
                        })
                        // if (handleCache(this.pageKey)) {
                        //   let dataCache = handleCache(this.pageKey).data
                        //   let data = this.list.map((item, index) => {
                        //     item.imgId = dataCache[index]['imgId']
                        //     item.imgUrl = dataCache[index]['imgUrl']
                        //     item.studentAnswer = dataCache[index]['studentAnswer']
                        //     return item
                        //   })
                        //   this.list = data
                        // }

                        this.textChange()
                    }
                })
            },
            butClick() {
                let message = '是否确定提交？提交后不能撤回！'
                for (let item of this.list) {

                    for (let i in item.studentAnswer) {

                        // 如果题目是学生批改
                        if (item.correctable == 'studentCorrecte') {
                            if (item.studentAnswer[i].length == 0 || item.studentImage == '') {
                                message = '您还有未批改的题目，是否确定提交？提交后不能撤回！'
                                break
                            }
                        }
                        if (item.correctable == 'teacherCorrecte') {
                            // 如果为填空题
                            if (item.type == 'empty_spaces') {
                                if (item.studentImage == '') {
                                    message = '您还有未操作的题目，是否确定提交？提交后不能撤回！'
                                    break
                                }
                            } else {
                                if (item.studentAnswer[i].length == 0) {
                                    message = '您还有未批改的题目，是否确定提交？提交后不能撤回！'
                                    break
                                }
                            }
                        }
                    }
                }

                this.$dialog
                    .confirm({
                        message: message
                    })
                    .then(() => {
                        this.submitAnswer.test_id = this.testId
                        this.submitAnswer.student_id = this.studentId
                        this.submitAnswer.data = []
                        this.list.forEach(item => {
                            let obj = {
                                show_order: item.showOrder,
                                partitionid: item.partitionid,
                                studentAnswer: undefined,
                                studentAnswerScore: undefined,
                                studentAnswerUrl: item.studentImage,
                            }
                            if (item.question_type === 'empty_spaces') {

                                if (Object.keys(item.empty_score).length > 0) {
                                    let empty_spaces_score = []
                                    for (let i in item.empty_score) {
                                        if (item.studentAnswer[i]) {
                                            empty_spaces_score.push((item.studentAnswer[i] / 100) * item.empty_score[i] )
                                        } else {
                                            empty_spaces_score.push(0)
                                        }
                                    }
                                    obj.studentAnswerScore = empty_spaces_score;
                                }
                                this.submitAnswer.data.push(obj)
                            }
                            else if(item.question_type === 'multiple_one' || item.question_type === "multiple_many") {
                                if (typeof (item.studentAnswer) == 'string') {
                                    obj.studentAnswer = [item.studentAnswer];
                                } else if (typeof (item.studentAnswer) == 'object') {
                                    obj.studentAnswer = item.studentAnswer
                                }
                                this.submitAnswer.data.push(obj)
                            }else if(item.question_type === "big_spaces"){

                                item.sub_question.forEach(sub_question => {

                                    let obj = {
                                        show_order: sub_question.show_order,
                                        partitionid: sub_question.partitionid,
                                        studentAnswer: undefined,
                                        studentAnswerScore: undefined,
                                        studentAnswerUrl: sub_question.studentImage,
                                    }

                                    if (sub_question.question_type === 'empty_spaces'){
                                        if (Object.keys(sub_question.empty_score).length > 0) {
                                            let empty_spaces_score = []
                                            for (let i in sub_question.empty_score) {
                                                if (sub_question.studentAnswer[i]) {
                                                    empty_spaces_score.push((sub_question.studentAnswer[i] / 100) * sub_question.empty_score[i])
                                                } else {
                                                    empty_spaces_score.push(0)
                                                }
                                            }
                                            obj.studentAnswerScore = empty_spaces_score;
                                        }
                                    }else {
                                        if (typeof (sub_question.studentAnswer) == 'string') {
                                            obj.studentAnswer = [sub_question.studentAnswer];
                                        } else if (typeof (sub_question.studentAnswer) == 'object') {
                                            obj.studentAnswer = sub_question.studentAnswer
                                        }
                                    }
                                    this.submitAnswer.data.push(obj)
                                })
                            }
                        })

                        this.loading = true
                        taskApi.submitTestCorrectionAnswer(this.submitAnswer).then(res => {
                            if (res.success) {
                                this.loading = false
                                this.correctable = true
                                this.$dialog
                                    .alert({
                                        message: '提交成功！'
                                    })
                                    .then(() => {
                                        if (this.correctableType == 'studentWaitingPhoto') {
                                            // this.$router.push({
                                            //   name: 'home'
                                            // })
                                            window.location.href = '/task'
                                        } else {
                                            this.$router.push({
                                                name: 'titleList',
                                                query: {testId: this.testId}
                                            })
                                        }
                                    })
                            }
                        })
                    })
                    .catch(() => {
                        // on cancel
                    })
            }
        },
        watch: {
            $route(to, from) {
                this.studentId = to.query.studentId
                this.testId = to.query.testId
                this.classId = to.query.classId
                this.correctableType = to.query.correctable
                this.pageKey = this.studentId + this.testId + this.classId
                // this.getList()
            }
        },

        created() {

        },
    }
</script>
<style lang="scss">
    #main.feedbackPage {


        .title_r {
            float: right;
            color: red;
            margin: 10px 10px 0 0;
        }

        .original {
            padding: 30px 0;
            font-size: 14px;

            .tit {
                text-indent: 15px;
            }
        }

        .mr5 {
            margin-right: 5px;
        }

        .mb10 {
            margin-bottom: 10px;
        }

        .maximg {

            ::v-deep img {
                max-width: 100%;
            }
        }

        .main {
            padding-bottom: 64px;

            .questionText {
                padding: 0 15px;
            }
            .titel {
                background: #FFFFFF;

                box-shadow: 0px 3px 23px 1px rgba(0, 51, 75, 0.13);
            }

            h4 {
                margin: 0;
                padding: 12px;
                background-color: #fff;
                text-align: left;
            }
        }

        .van-cell__title {
            background: #FFFFFF;
            box-shadow: 0px 3px 23px 1px rgba(0, 51, 75, 0.13);
            border-radius: 10px;
            padding: 10px;

            .cell_title {
                margin: 7px 0;
                text-align: left;

                .van-cell__label {

                    .van-radio__label {
                        width: 90px;
                        height: 50px;
                        background: #FFFFFF;
                        border-radius: 10px;
                        border: 1px solid #333333;
                    }
                }

                .box {
                    width: 90%;
                    overflow-x: auto;
                    line-height: 2;

                    ::v-deep img {
                        max-width: 100%;
                    }

                    ::v-deep table,
                    table tr th,
                    ::v-deep table tr td {
                        border: 1px solid #ccc;
                        padding: 2px;
                    }

                    ::v-deep table {
                        border-collapse: collapse;
                        width: 100%;
                    }
                }
            }
        }

        .empty_cell {
            padding-bottom: 10px;
        }

        .optionAnswer{
            line-height: 60px;
            .checkbox{
                height: 60px;
                line-height: 60px;
                font-size: 1.2em;
                .van-radio{
                    margin-right: 1em;
                }
                .van-radio__icon{
                    font-size: 1.2em;
                }
            }
        }
        .cell_desc {
            padding-top: 10px;
            font-size: 16px;
            .mb10{
                height: 60px;
                line-height: 60px;
            }

            ::v-deep .van-radio__label,
            ::v-deep .van-checkbox__label {

                width: 40px;
                line-height: 24px;
                background: #FFFFFF;
                border-radius: 10px;
                border: 1px solid #333333;
            }


            ::v-deep .van-radio__icon,
            ::v-deep .van-checkbox__icon {
                .van-icon {
                    display: none;
                }
            }

            ::v-deep .van-radio__icon--checked + .van-radio__label,
            ::v-deep .van-checkbox__icon--checked + .van-checkbox__label {
                color: #fff;

                border: 1px solid #FFF;
                /*background-color: #019fe8;*/
                /*padding: 0 5px;*/
                span, i {
                    width: 40px;
                    display: inline-block;
                    line-height: 24px;
                    text-align: center;
                    border-radius: 10px;

                }

                span {
                    background-color: #019fe8;
                    /*padding: 0 5px;*/
                }

                i {
                    /*padding: 0 5px;*/
                }

                .my-icon-dui {
                    background-color: #01d06c;
                    border: 1px solid #01d06c;
                }

                .my-icon-bandui {
                    background-color: #e89401;
                }

                .my-icon-cuo {
                    background-color: #ff5565;
                }
            }
        }

        .footer {
            background-color: #fff;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;

            .foot {
                padding: 10px 16px;
            }
        }
    }

    //兼容ipad
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        #main.feedbackPage {
            .van-nav-bar__content {
                height: 92px;
                line-height: 44px;

                .van-nav-bar__title {
                    font-size: 32px;
                }

                .van-nav-bar__arrow {
                    font-size: 32px;
                }

                .van-nav-bar__text {
                    font-size: 28px;
                }
            }

            .van-uploader__preview-image {
                width: 160px;
                height: 160px;
            }

            .van-cell {
                font-size: 28px;
            }

            .cell_desc {
                font-size: 32px;
            }

            .van-uploader__upload {
                width: 160px;
                height: 160px;

            }

            .van-uploader__upload-icon {
                font-size: 48px;
            }


            .title_r {
                height: 80px;
                line-height: 80px;
                margin: 0 24px 0 auto;
                font-size: 24px !important;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;

                .van-checkbox__label {
                    line-height: 80px;
                }
            }

            .original {
                padding: 60px 0;
                font-size: 28px;

                .tit {
                    text-indent: 30px;
                }
            }

            .mr5 {
                margin-right: 10px;
            }

            .mb10 {
                margin-bottom: 20px;
            }


            .main {
                padding-bottom: 128px;

                .titel {
                    /*box-shadow: 0px 6px 46px 2px rgba(0, 51, 75, 0.13);*/
                    height: 88px;
                }

                h4 {
                    padding: 24px !important;
                }
            }

            .van-cell__title {
                box-shadow: 0px 6px 46px 2px rgba(0, 51, 75, 0.13);
                border-radius: 20px;
                padding: 20px;

                .cell_title {
                    margin: 14px 0;

                    .van-cell__label {

                        .van-radio__label {
                            width: 180px;
                            height: 100px;
                            border-radius: 20px;
                        }
                    }

                    .box {

                        ::v-deep table,
                        table tr th,
                        ::v-deep table tr td {
                            padding: 4px;
                        }

                    }
                }
            }

            .empty_cell {
                padding-bottom: 20px;
            }

            .cell_desc {
                padding-top: 20px;
                font-size: 32px;

                ::v-deep .van-radio__label,
                ::v-deep .van-checkbox__label {

                    width: 80px;
                    line-height: 48px;
                    border-radius: 20px;
                }


                ::v-deep .van-radio__icon--checked + .van-radio__label,
                ::v-deep .van-checkbox__icon--checked + .van-checkbox__label {
                    span, i {
                        width: 80px;
                        line-height: 48px;
                        border-radius: 20px;

                    }
                }
            }

            .footer {

                .foot {
                    padding: 20px 32px;
                }

                .van-button--normal {
                    font-size: 28px;
                    height: 88px;
                }
            }
        }
    }
</style>
